<template>
  <div class="category-list-page">
    <Breadcrumbs />
    <div v-if="category.brand == null">
      <div class="container">
        <div class="row">
          <ProductFilter class="col-lg-3 cst-3" />
          <CategoryOverview
            class="col-lg-9 cst-9"
            v-if="category.is_anchor == 0"
          />
          <ProductsListingV2
            class="col-lg-9 cst-9"
            v-if="category.is_anchor == 1"
          />
        </div>
        <div v-if="categoryForm != null">
          <dynamic-forms :category_id="category.id" type="category" />
        </div>
      </div>
    </div>
    <div v-if="category.brand != null">
      <div class="container" style="text-align: right">
        <img style="width: 150px" :src="category.brand.logo" />
      </div>
      <!-- <CategoryOverview /> -->
      <div class="container" v-if="category.is_anchor == 1">
        <div class="row">
          <ProductFilter class="col-lg-3 cst-3" />
          <ProductsListingV2 class="col-lg-9 cst-9" />
        </div>
        <div v-if="categoryForm != null">
          <dynamic-forms :category_id="category.id" type="category" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs";
import ProductFilter from "@/base/core/components/category-product/ProductFilter";
import CategoryOverview from "@/base/core/components/category-product/CategoryOverview.vue";
import ProductsListingV2 from "@/base/core/components/category-product/ProductListingV2";
import DynamicForms from "@/base/core/components/common/DynamicForms.vue";

export default {
  name: "CategoryOverviews",
  components: {
    Breadcrumbs,
    ProductFilter,
    ProductsListingV2,
    CategoryOverview,
    DynamicForms,
    // CategoryPageContentFilter
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    categoryId() {
      return this.$store.getters["category/getCurrentCategoryId"];
    },
    categories() {
      return this.category.children;
    },
    categoryForm() {
      // console.log("cat form", this.$store.getters["forms/getCategoryForm"]);
      return this.$store.getters["forms/getCategoryForm"];
    },
    gtmProducts() {
      return this.$store.getters["category/getGtmProducts"];
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        const query = {};
        const pageSize = config.products?.perPage || 12;
        const perPage = this.$store.getters["category/getProductsPerPage"];
        if (pageSize != perPage) query["page-size"] = perPage;
        this.$router.push({ path: to.path, query: query });
      }
    },
    gtmProducts() {
      Logger.debug("this.$gtm.enabled()", "gtmProducts", this.$gtm.enabled())();
      if (this.$gtm.enabled()) {
        Logger.debug(
          "this.gtmProducts.length",
          "gtmProducts",
          this.gtmProducts.length
        )();
        if (this.gtmProducts.length > 0) {
          window.dataLayer.push({
            event: "eec.impressionView",
            ecommerce: {
              impressions: this.gtmProducts,
            },
          });
        }
      }
    },
    /*
    categoryId(oldV, newV) {
     
      if (oldV != newV) {
        Logger.debug("CategoryId watch", "Category.vue", this.categoryId)();
        this.$store.dispatch("category/loadProducts");
      }
      
    },
    */
  },
  mounted() {
    /*
    if (!isServer) {
      Logger.debug("MOUNTED", "Category.vue", this.categoryId)();
      if (this.categoryId != 0) {
        this.$store.dispatch("category/loadProducts");
      }
    }
    */
  },
  destroyed() {
    Logger.debug("DESTROYED", "Category.vue", this.categoryId)();
    this.$store.commit("category/setLoadProducts", false);
  },
  methods: {},
  data() {
    return {
      //    bannerBg: bannerImage,
      //categories: mockCategory,
    };
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.category ? this.category.meta_title : "",
        },
        {
          name: "keywords",
          content: this.category ? this.category.meta_keywords : "",
        },
        {
          name: "description",
          content: this.category ? this.category.meta_description : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.category.name,
      // all titles will be injected into this template
    };
  },
};
</script>

<style lang="scss" scoped>
.category-list-page {
  margin-bottom: 80px;
}
</style>